/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import {
    arrayOf,
    bool,
    func,
    object,
    shape,
    string,
} from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import {
    Button, CircularProgress,
} from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import { useUIDSeed } from 'react-uid';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import {
    clearPassportError, closePassportSideBar, setPassportData, triggerPassportSubscription,
} from '../../../../state/ducks/Passport/Passport-Action';
import {
    getPassportABData, getPassportCampaignData, getPassportData, getPassportError, getPassportItem, getPassportSideBarStatus, getPassportSignInData,
} from '../../../../state/ducks/Passport/Passport-Selectors';
import { getFeatureFlags } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { trackEvent } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { getCart } from '../../../../state/ducks/Cart/Cart-Actions';
import ResponsiveImage from '../../GraphqlComponents/GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';
import { getActiveABTests } from '../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';
import { getSkuWithoutBrand } from '../../../helpers/common/helper';

const styles = (theme) => ({
    container: {
        display: 'flex',
        [theme.breakpoints.down(768)]: {
            display: 'block',
            textAlign: 'center',
        },
    },
    contentContainer: {
        width: '75%',
        [theme.breakpoints.down(768)]: {
            width: '100%',
        },
    },
    header: {
        fontWeight: '700',
        marginBottom: '30px',
    },
    addCartBtn: {
        textTransform: 'none',
        width: '100%',
        margin: '12px 0',
        background: theme.palette.continueShoppingBg,
        color: theme.palette.common.white,
        '&:hover': {
            background: theme.palette.continueShoppingHoverBg,
        },

        [theme.breakpoints.down(1015)]: {
            width: '85%',
        },
    },
    passportAddtoCart: {
        margin: '30px auto',
    },
    loadingCircle: {
        marginLeft: 10,
    },
    redText: {
        fontWeight: 900,
        color: 'red',
        textAlign: 'center',
    },
});

const renderPassportCelebationData = ((classes, data, addToCartButton, passportDetails) => {
    const blockKey = Object?.keys(data)?.[0];
    switch (blockKey) {
        case 'image':
            return <img src={data?.image?.file?.url} className={classes.passportBannerImage} alt={data?.image?.file?.title} />;
        case 'cta':
            return (
                <div className={classes.passportAddtoCart}>
                    <p>Join today {passportDetails.price && `for $${passportDetails.price}`} for your first year! Automatically Renews for $29.99/Year, Cancel Anytime.</p>
                    {addToCartButton}
                    <Link
                        tabindex="-1"
                        to="/passport-terms-and-conditions"
                        aria-hidden="true"
                        target="_blank"
                        rel="noopener noreferrer"
                    ><div><u>For Full <span style={{ display: 'inline-block' }} />Terms & Conditions</u></div>
                    </Link>
                </div>
            );
        case 'copy':
            return <div className={classes.copyText}><ReactMarkdown source={data?.copy?.copy} escapeHtml={false} /></div>;
        default:
            return null;
    }
});

const PassportCelebrationModel = ({
    classes, celebrationData, triggerSubscription, passportData, passportError, actionGetCart, passportInCart,
    history, trackPassport, callClearPassportError, activeABTests, featureFlags, setPassportCodeAction, passportCampaignData,
}) => {
    if (Object.keys(celebrationData).length === 0) {
        return null;
    }
    const [loading, setLoading] = useState(false);
    const seed = useUIDSeed();

    useEffect(() => {
        actionGetCart();
        if (passportError) callClearPassportError();
    }, []);

    const passportDetails = {};
    // setting passport messaging and sku
    passportDetails.passportEnrolmentTerm = passportData?.duration_short_text || 'year';
    passportDetails.itemSku = passportData?.item_id;
    passportDetails.price = passportData?.price;

    if (!passportCampaignData?.enable && featureFlags['passport-google-optimize']) {
        // Google Optimize AB Test
        if (
            (activeABTests.loaded || activeABTests.timeout)
            && passportData?.ab_testing?.variant?.passport_referenced_sku?.[0]?.price &&  passportData?.ab_testing?.control?.passport_referenced_sku?.[0]?.price
        ) {
            const passport = { ...passportData };
            if (activeABTests.passportSkuTest === 'Rule_One') {
                const passportVariant = passportData.ab_testing.variant.passport_referenced_sku[0];
                passportDetails.itemSku = passportVariant.item_sku;
                passportDetails.price = passportVariant.price;

                if (passportVariant.price !== passport.price) {
                    passport.item_id = getSkuWithoutBrand(passportVariant.item_sku);
                    passport.price = passportVariant.price;
                    setPassportCodeAction(passport);
                }
            } else {
                const passportControl = passportData.ab_testing.control.passport_referenced_sku[0];
                passportDetails.itemSku = passportControl.item_sku;
                passportDetails.price = passportControl.price;

                if (passportControl.price !== passport.price) {
                    passport.item_id = getSkuWithoutBrand(passportControl.item_sku);
                    passport.price = passportControl.price;
                    setPassportCodeAction(passport);
                }
            }
        }
    }

    const addToCartBtn = (
        <div>
            {passportError && <p className={classes.redText}>{passportError}</p>}
            <Button
                className={classes.addCartBtn}
                variant="contained"
                disabled={passportError.length > 0}
                onClick={() => {
                    if (!loading) setLoading(true);
                    trackPassport({
                        eventCategory: 'Passport Click',
                        eventAction: 'Passport EyeBrow Click',
                        eventLabel: `Add to Cart | ${passportDetails.price} | ${passportDetails.passportEnrolmentTerm}`,
                    });
                    triggerSubscription('passportPage', passportDetails?.itemSku);
                }}
            >
                Add to Cart
                {(loading && !passportError)
                    && <CircularProgress size={24} color="inherit" className={classes.loadingCircle} />}
            </Button>
        </div>
    );
    const goToCheckoutBtn = (
        <Button
            className={classes.addCartBtn}
            variant="contained"
            onClick={() => {
                if (loading) setLoading(false);
                // Close side banner

                if (passportInCart?.[0]?.ordersId) {
                    history.push(`/checkout/cart/${passportInCart[0].ordersId}`);
                }
            }}
        >
            Go To Checkout
        </Button>
    );

    const addToCartButton = passportInCart.length < 1
        ? addToCartBtn
        : goToCheckoutBtn;

    return (
        <div className={classes.container}>
            <ResponsiveImage path={celebrationData?.banner_image?.url} alt={celebrationData?.banner_image?.description || 'Celebrations Passport Image'} params={{}} />
            <div className={classes.contentContainer}>
                <div className={classes.header}><b>{celebrationData.modal_title}</b></div>
                {celebrationData?.modular_blocks?.map((blocks) => (
                    <div key={seed(blocks)}>
                        {renderPassportCelebationData(classes, blocks, addToCartButton, passportDetails)}
                    </div>
                ))}
            </div>
        </div>
    );
};
PassportCelebrationModel.propTypes = {
    activeABTests: object.isRequired,
    classes: object.isRequired,
    celebrationData: shape({
        title: string,
        modular_blocks: arrayOf(shape({
            image: shape({
                file: shape({
                    url: string,
                    title: string,
                }),
            }),
        })),
    }),
    triggerSubscription: func.isRequired,
    passportData: shape({
        item_id: string,
        duration_short_text: string,
        passport_messaging: shape({
            user_with_expired_passport_subscription: shape({
                message_for_enrolment_page: string,
                passportsku: string,
            }),
            user_whose_passport_subscription_is_about_to_expire: shape({
                message_for_enrolment_page: string,
                passportsku: string,
            }),
            user_who_never_bought_passport_subscription: shape({
                message_for_enrolment_page: string,
                passportsku: string,
            }),
        }),
    }),
    passportError: string.isRequired,
    actionGetCart: func.isRequired,
    passportInCart: object.isRequired,
    history: object.isRequired,
    trackPassport: func,
    featureFlags: object,
    callClearPassportError: func.isRequired,
    setPassportCodeAction: func.isRequired,
    passportCampaignData: shape({
        enable: bool,
    }),
};
PassportCelebrationModel.defaultProps = {
    celebrationData: {},
    passportData: {},
    featureFlags: {},
    trackPassport: () => { },
    passportCampaignData: {},
};
const mapStateToProps = (state) => ({
    activeABTests: getActiveABTests(state),
    featureFlags: getFeatureFlags(state),
    passportData: getPassportData(state),
    passportError: getPassportError(state),
    passportInCart: getPassportItem(state),
    passportABData: getPassportABData(state),
    passportSignInData: getPassportSignInData(state),
    passportCampaignData: getPassportCampaignData(state),
    passportSideBarStatus: getPassportSideBarStatus(state),
});

const mapDispatchToProps = (dispatch) => ({
    trackPassport: bindActionCreators(trackEvent, dispatch),
    callClearPassportError: bindActionCreators(clearPassportError, dispatch),
    closePassportSideBanner: bindActionCreators(closePassportSideBar, dispatch),
    triggerSubscription: bindActionCreators(triggerPassportSubscription, dispatch),
    setPassportCodeAction: bindActionCreators(setPassportData, dispatch),
    actionGetCart: bindActionCreators(getCart, dispatch),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PassportCelebrationModel)),
);
