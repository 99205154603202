/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object } from 'prop-types';
import { withStyles } from '@material-ui/styles';

import GenericSkeleton from '../../GraphqlComponents/GraphqlSkeletonComponents/GenericSkeleton';

const styles = (theme) => ({
    container: {
        width: '60vw',
        maxWidth: '700px',
        minWidth: '200px',
        height: '350px',
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down(768)]: {
            width: '70vw',
            display: 'block',
        },
    },
    bannerImg: {
        width: '30%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.down(768)]: {
            width: '80%',
            margin: 'auto',
        },
    },
    contentContainer: {
        width: '60%',
        [theme.breakpoints.down(768)]: {
            width: '90%',
            margin: 'auto',
        },
    },
});

const PassportCelebrationGenericLoader = ({ classes }) => (
    <div className={classes.container}>
        <div className={classes.bannerImg}><GenericSkeleton height="250px" width="100%" /></div>
        <div className={classes.contentContainer}>
            <p><GenericSkeleton height="30px" width="100%" /></p>
            <br />
            <p>
                <GenericSkeleton height="10px" width="100%" />
                <GenericSkeleton height="10px" width="100%" />
                <GenericSkeleton height="10px" width="100%" />
            </p>
            <br />
            <p>
                <GenericSkeleton height="10px" width="80%" />
                <GenericSkeleton height="30px" width="100%" />
                <GenericSkeleton height="10px" width="40%" />
            </p>
        </div>
    </div>
);

PassportCelebrationGenericLoader.propTypes = {
    classes: object.isRequired,
};

export default withStyles(styles)(PassportCelebrationGenericLoader);
