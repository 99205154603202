/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { useQuery } from '@apollo/client';
import {
    bool, object, func, shape,
} from 'prop-types';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogContent from '@material-ui/core/DialogContent';
import {
    Box,
    CircularProgress, Dialog, DialogTitle, IconButton,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import PassportCelebrationModel from './PassportCelebrationModel';
import PassportCelebrationModelV2 from './PassportCelebrationModelV2';
import findContent from '../../../gql/queries/findContent';
import { getFeatureFlags } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import PassportCelebrationGenericLoader from './PassportCelebrationGenericLoader';

const styles = (theme) => ({
    dialogContent: {
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down(381)]: {
            '& .MuiDialogTitle-root': {
                padding: '16px 6px',
                '& h1,h2,h3,h4,h5,h6': {
                    fontSize: 18,
                },
            },
        },
    },
    contentPassport: {
        '& p,ol,li': {
            fontSize: 16,
        },
        '& h1,h2,h3,h4,h5,h6': {
            fontSize: 18,
        },
        '& img': {
            width: '100%',
            [theme.breakpoints.down(768)]: {
                width: '50%',
            },
        },
        '& a': {
            color: theme.palette.common?.black || 'black',
            textAlign: 'center',
        },
        '& a > div': {
            marginTop: '10px',
        },
    },
    center: {
        display: 'flex',
        justifyContent: 'center',
    },
});

const PassportEyeBrowContainer = ({
    isModalOpen, classes, handleClose, featureFlags,
}) => {
    const CELEBRATION_PASSPORT_DATA = findContent({
        brand: 'celebrations',
        contentType: 'modal_content',
        query: '\\"title\\" : \\"Celebrations Passport\\"',
    });
    const { data, error, loading } = useQuery(CELEBRATION_PASSPORT_DATA);
    const promoMessaging = data?.findContent?.content?.entries?.[0]?.modal_title || 'Celebrations Passport';
    const CELEBRATION_PASSPORT_MODAL_V2 = featureFlags['is-celebrations-passport-modal-v2-enabled'];
    if (error) {
        return null;
    }

    return (
        <Dialog open={isModalOpen} maxWidth="md">
            <div className={classes.dialogContent}>
                {!CELEBRATION_PASSPORT_MODAL_V2 && (
                    <DialogTitle id="customized-dialog-title">
                        {promoMessaging}
                    </DialogTitle>
                )}
                <IconButton data-testid="close-button" className={classes.closeButton} aria-label="close" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </div>
            <MuiDialogContent dividers={!CELEBRATION_PASSPORT_MODAL_V2}>
                <Typography gutterBottom>
                    <div className={classes.contentPassport}>
                        {CELEBRATION_PASSPORT_MODAL_V2 && loading && <PassportCelebrationGenericLoader />}
                        {
                            !CELEBRATION_PASSPORT_MODAL_V2 && loading
                            && (
                                <Box className={classes.center}>
                                    <CircularProgress />
                                </Box>
                            )
                        }
                        {
                            data?.findContent?.content?.entries?.[0]
                            && (CELEBRATION_PASSPORT_MODAL_V2 ? <PassportCelebrationModelV2 celebrationData={data?.findContent?.content?.entries?.[0]} /> : <PassportCelebrationModel celebrationData={data?.findContent?.content?.entries?.[0]} />)
                        }
                    </div>
                </Typography>
            </MuiDialogContent>
        </Dialog>
    );
};

PassportEyeBrowContainer.propTypes = {
    isModalOpen: bool,
    classes: object.isRequired,
    handleClose: func,
    featureFlags: shape({
        'is-celebrations-passport-modal-v2-enabled': bool,
    }),
};

PassportEyeBrowContainer.defaultProps = {
    isModalOpen: false,
    handleClose: () => { },
    featureFlags: {},
};

const mapStateToProps = (state) => ({
    featureFlags: getFeatureFlags(state),
});

export default connect(mapStateToProps, null)(withStyles(styles)(PassportEyeBrowContainer));
