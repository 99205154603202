/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import {
    arrayOf,
    bool,
    func,
    object,
    shape,
    string,
} from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import {
    Button, CircularProgress,
} from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import { useUIDSeed } from 'react-uid';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    clearPassportError, closePassportSideBar, setPassportData, triggerPassportSubscription,
} from '../../../../state/ducks/Passport/Passport-Action';
import {
    getPassportABData, getPassportCampaignData, getPassportData, getPassportError, getPassportItem, getPassportSideBarStatus, getPassportSignInData,
} from '../../../../state/ducks/Passport/Passport-Selectors';
import { getFeatureFlags } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { trackEvent } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { getCart } from '../../../../state/ducks/Cart/Cart-Actions';
import { getActiveABTests } from '../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';
import { getSkuWithoutBrand } from '../../../helpers/common/helper';

const styles = (theme) => ({
    dialogContent: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down(381)]: {
            '& .MuiDialogTitle-root': {
                padding: '16px 6px',
                '& h1,h2,h3,h4,h5,h6': {
                    fontSize: 18,
                },
            },
        },
    },
    contentpassport: {
        '& p,ol,li': {
            fontSize: 16,
        },
        '& h1,h2,h3,h4,h5,h6': {
            fontSize: 18,
        },
        '& img': {
            width: '100%',
        },
    },
    infoIconContainer: {
        display: 'flex',
    },
    addCartBtn: {
        color: 'white',
        margin: '1.5rem 1rem',
        padding: '8px 1em',
        fontSize: '1.2rem',
        background: 'rgb(63 125 155)',
        fontWeight: '900',
        borderRadius: '10px',
        textTransform: 'capitalize',
        [theme.breakpoints.down(481)]: {
            margin: '1.5rem 0rem',
            padding: '8px 0.5em',
        },
        '&:hover': {
            background: 'rgb(103,149,172)',
        },
    },
    passportAddtoCart: {
        textAlign: 'center',
    },
    loadingCircle: {
        marginLeft: 10,
    },
});

const renderPassportCelebationData = ((classes, data, addToCartButton) => {
    const blockKey = Object?.keys(data)?.[0];
    switch (blockKey) {
        case 'image':
            return <img src={data?.image?.file?.url} className={classes.passportBannerImage} alt={data?.image?.file?.title} />;
        case 'cta':
            return <div className={classes.passportAddtoCart}>{addToCartButton}</div>;
        case 'copy':
            return <div className={classes.copyText}><ReactMarkdown source={data?.copy?.copy} escapeHtml={false} /></div>;
        default:
            return null;
    }
});

const PassportCelebrationModel = ({
    classes, celebrationData, triggerSubscription, passportData, passportError, actionGetCart,
    passportInCart, history, trackPassport, activeABTests, featureFlags, setPassportCodeAction,
    passportCampaignData,
}) => {
    if (Object.keys(celebrationData).length === 0) {
        return null;
    }
    const [loading, setLoading] = useState(false);
    const seed = useUIDSeed();

    useEffect(() => {
        actionGetCart();
    }, []);

    const passportDetails = {};
    // setting passport messaging and sku
    passportDetails.passportEnrolmentTerm = passportData?.duration_short_text || 'year';
    passportDetails.itemSku = passportData?.item_id;
    passportDetails.price = passportData?.price;

    if (!passportCampaignData?.enable && featureFlags['passport-google-optimize']) {
        // Google Optimize AB Test
        if (
            (activeABTests.loaded || activeABTests.timeout)
            && passportData?.ab_testing?.variant?.passport_referenced_sku?.[0]?.price &&  passportData?.ab_testing?.control?.passport_referenced_sku?.[0]?.price
        ) {
            const passport = { ...passportData };
            if (activeABTests.passportSkuTest === 'Rule_One') {
                const passportVariant = passportData.ab_testing.variant.passport_referenced_sku[0];
                passportDetails.itemSku = passportVariant.item_sku;
                passportDetails.price = passportVariant.price;

                if (passportVariant.price !== passport.price) {
                    passport.item_id = getSkuWithoutBrand(passportVariant.item_sku);
                    passport.price = passportVariant.price;
                    setPassportCodeAction(passport);
                }
            } else {
                const passportControl = passportData.ab_testing.control.passport_referenced_sku[0];
                passportDetails.itemSku = passportControl.item_sku;
                passportDetails.price = passportControl.price;

                if (passportControl.price !== passport.price) {
                    passport.item_id = getSkuWithoutBrand(passportControl.item_sku);
                    passport.price = passportControl.price;
                    setPassportCodeAction(passport);
                }
            }
        }
    }

    const addToCartBtn = (
        <div>
            <Button
                className={classes.addCartBtn}
                variant="contained"
                disabled={passportError.length > 0}
                onClick={() => {
                    if (!loading) setLoading(true);
                    trackPassport({
                        eventCategory: '<<pageType>>',
                        eventAction: 'Passport EyeBrow Click',
                        eventLabel: `Add to Cart | ${passportDetails.price} | ${passportDetails.passportEnrolmentTerm}`,
                    });
                    triggerSubscription('passportPage', passportDetails?.itemSku);
                }}
            >
                Add to cart ${passportDetails.price} /{passportDetails.passportEnrolmentTerm}
                {(loading && !passportError)
                    && <CircularProgress size={24} color="inherit" className={classes.loadingCircle} />}
            </Button>
        </div>
    );
    const goToCheckoutBtn = (
        <Button
            className={classes.addCartBtn}
            variant="contained"
            onClick={() => {
                if (loading) setLoading(false);
                // Close side banner

                if (passportInCart?.[0]?.ordersId) {
                    history.push(`/checkout/cart/${passportInCart[0].ordersId}`);
                }
            }}
        >
            Go To Checkout
        </Button>
    );

    const continueCheckoutBtn = (
        <div>
            {goToCheckoutBtn}
        </div>
    );

    const addToCartButton = passportInCart.length < 1
        ? addToCartBtn
        : (
            <>
                {continueCheckoutBtn}
            </>
        );

    return (
        celebrationData?.modular_blocks?.map((blocks) => (
            <div key={seed(blocks)}>
                {renderPassportCelebationData(classes, blocks, addToCartButton)}
            </div>
        ))
    );
};
PassportCelebrationModel.propTypes = {
    activeABTests: object.isRequired,
    classes: object.isRequired,
    celebrationData: shape({
        title: string,
        modular_blocks: arrayOf(shape({
            image: shape({
                file: shape({
                    url: string,
                    title: string,
                }),
            }),
        })),
    }),
    triggerSubscription: func.isRequired,
    passportData: shape({
        item_id: string,
        duration_short_text: string,
        passport_messaging: shape({
            user_with_expired_passport_subscription: shape({
                message_for_enrolment_page: string,
                passportsku: string,
            }),
            user_whose_passport_subscription_is_about_to_expire: shape({
                message_for_enrolment_page: string,
                passportsku: string,
            }),
            user_who_never_bought_passport_subscription: shape({
                message_for_enrolment_page: string,
                passportsku: string,
            }),
        }),
    }),
    passportError: string.isRequired,
    actionGetCart: func.isRequired,
    passportInCart: object.isRequired,
    history: object.isRequired,
    trackPassport: func,
    setPassportCodeAction: func.isRequired,
    featureFlags: object,
    passportCampaignData: shape({
        enable: bool,
    }),
};
PassportCelebrationModel.defaultProps = {
    celebrationData: {},
    passportData: {},
    trackPassport: () => { },
    featureFlags: {},
    passportCampaignData: {},
};
const mapStateToProps = (state) => ({
    activeABTests: getActiveABTests(state),
    featureFlags: getFeatureFlags(state),
    passportData: getPassportData(state),
    passportError: getPassportError(state),
    passportInCart: getPassportItem(state),
    passportABData: getPassportABData(state),
    passportSignInData: getPassportSignInData(state),
    passportCampaignData: getPassportCampaignData(state),
    passportSideBarStatus: getPassportSideBarStatus(state),
});

const mapDispatchToProps = (dispatch) => ({
    trackPassport: bindActionCreators(trackEvent, dispatch),
    callClearPassportError: bindActionCreators(clearPassportError, dispatch),
    closePassportSideBanner: bindActionCreators(closePassportSideBar, dispatch),
    triggerSubscription: bindActionCreators(triggerPassportSubscription, dispatch),
    setPassportCodeAction: bindActionCreators(setPassportData, dispatch),
    actionGetCart: bindActionCreators(getCart, dispatch),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PassportCelebrationModel)),
);
